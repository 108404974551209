import React from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"

const SportSafetyPage = ({ data }) => {
  const page = data.wagtail.page
  return (
    <Layout
      isContent={true}
      subMenu={data.wagtail.page.children}
      button={data.wagtail.page.button}
      parent={"sport-safety"}
      siteTitle={"Sport Safety"}
    >
      <section className="breadcrumb-area breadcrumb-sub-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb nav-breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="tab-content-area">
          <div className="container simple-page">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-12">
                    <h1>{page.title}</h1>
                    <p>{page.descriptionSimple}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div
                      className="c-title-content mb-80"
                      dangerouslySetInnerHTML={{
                        __html: data.wagtail.page.bodySafe,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

SportSafetyPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export const query = graphql`
  {
    wagtail {
      page(slug: "sport-safety") {
        ... on SimplePage {
          id
          body
          bodySafe
          descriptionSimple
          button {
            name
            text
            url
          }
          title
          children {
            ... on SimplePage {
              id
              title
              slug
              live
              isProtected
            }
          }
        }
      }
    }
  }
`
export default SportSafetyPage
